import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const HiddenBold = styled.b`
  visibility: hidden;
`

const Container = styled.div`
  display: grid;
  grid-template: 1fr / 1fr;

  > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

type SupportedTypography = typeof BodyText | typeof Caption

type Props<TypographyComponent extends SupportedTypography = SupportedTypography> = ComponentProps<TypographyComponent> & {
  Typography: TypographyComponent
}

/**
 * Preserves the max width for the bold variant of the given typography to avoid layout shift upon transition.
 */
function MaxWeightPreservedTypography<TypographyComponent extends SupportedTypography>(props: Props<TypographyComponent>) {
  const { Typography, children, ...rest } = props

  return <Container {...rest} as={Typography}>
    <span>{children}</span>
    <HiddenBold aria-hidden="true">{children}</HiddenBold>
  </Container>
}

export default MaxWeightPreservedTypography
